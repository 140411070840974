//下载过滤器 例如超过一万就显示1.几万
function downloadCount(value) {
  let str = value || 0;
  if (value >= 10000) {
    const totleNum = (value / 10000).toFixed(1);
    str = `${parseFloat(totleNum)}W`;
  } else if (value >= 1000) {
    const totleNum = (value / 1000).toFixed(1);
    str = `${parseFloat(totleNum)}K`;
  }
  return str;
}

export default {
  downloadCount
}