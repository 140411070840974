<template>
  <div class="lou_feng" :class="appClass">
    <Home :appId="appId" :url="url" :token="token" v-if="page == 'home'" />
    <Topic
      :swiperData="swiperData"
      :url="url"
      :token="token"
      v-else-if="page == 'topic'"
    />
    <Entertainment :appId="appId" :swiperData="swiperData" v-else />
    <!-- 加载中... -->
    <van-overlay :show="isAdvLoading" z-index="100">
      <div class="wrapper">
        <van-loading color="#1989fa" text-color="#0094ff" vertical
          >{{ loadingText }}...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getSwipers } from "@/api/index.js";
import { getQueryString } from "@/utils/index.js";
import Entertainment from "./Entertainment.vue";
import Home from "./Home.vue";
import Topic from "./Topic.vue";
export default {
  name: "Loufeng",
  components: {
    Entertainment,
    Home,
    Topic,
  },
  data() {
    return {
      appClass: "normal",
      appId: getQueryString("appId"),
      page: getQueryString("page"),
      url: getQueryString("u"),
      token: getQueryString("t"),
      isAdvLoading: true,
      loadingText: "未携带appId",
      swiperData: [],
    };
  },
  created() {
    if (this.appId) {
      this.loadingText = "数据加载中";
      this.getSwiperData();
    }
  },
  mounted() {
    switch (this.appId) {
      case "1":
        this.appClass = "bs_theme"; // 泡芙
        break;
      case "3":
        this.appClass = "yp_theme"; // 约炮
        break;
      case "7":
        this.appClass = "sis_theme"; // 色中色
        break;
      case "13":
        this.appClass = "hl_theme"; // 黑料
        break;
      case "14":
        this.appClass = "yj_theme"; // 妖精
        break;
      case "15":
        this.appClass = "mt_theme"; // 蜜桃
        break;
      case "16":
        this.appClass = "hx_theme"; // 红杏
        break;
      case "17":
        this.appClass = "wy_theme"; // 无忧
        break;
      case "18":
        this.appClass = "wyt_theme"; // 五月天
        break;
      case "19":
        this.appClass = "yc_theme"; // 瑶池
        break;
      case "20":
        this.appClass = "zy_theme"; // 知音
        break;
      case "21":
        this.appClass = "cs_theme"; //春水
        break;
      case "22":
        this.appClass = "yhy_theme"; //怡红院
        break;
      case "24":
        this.appClass = "bs_theme"; // 51本色
        break;
      case "26":
        this.appClass = "xhs_theme"; // 小黄书
        break;
      default:
        this.appClass = "bs_theme"; // 默认
        break;
    }
  },
  methods: {
    // 获取轮播图数据
    async getSwiperData() {
      let ret = await this.$Api(getSwipers, this.appId);
      this.isAdvLoading = false;
      if (ret.code == 200 && ret.data) {
        this.swiperData = ret.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lou_feng {
  font-size: 12px;
  height: 100%;
  background-color: rgb(240, 240, 240);
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.95);
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
