import axios from "@/utils/request";

// 获取广告
export function getAdvertise(data){
  return axios.post(`/api/show/lfs/bmRZThqTHr08r3N6`, data);
}
// 获取楼风数据
export function getLoufengList(data){
  return axios.post(`/api/show/lfs/LnW7xOQioBbUZqvj`, data);
}
// 请求次数
export function addClick(data){
  return axios.post(`api/show/lfs/k646u2qybotl7h17`, data);
}
// 获取热门数据
export function getHots(params){
  return axios.post(`/api/application/cgpsx61g/hot/` + params);
}
// 获取视频数据
export function getVideos(params){
  return axios.post(`/api/application/cgpsx61g/video/` + params);
}
// 获取直播数据
export function getLives(params){
  return axios.post(`/api/application/cgpsx61g/zhibo/` + params);
}
// 获取轮播图数据
export function getSwipers(params){
  return axios.post(`/api/application/u3ej4h1v/` + params);
}
