<template>
  <div class="topic">
    <div
      class="banner"
      @click="jumpAdv(item)"
      v-for="item in swiperData"
      :key="item.id"
    >
      <ImgDecypt :src="item.image" />
    </div>
    <div class="no_data" v-if="swiperData.length == 0">
      <div class="text">暂无数据</div>
    </div>
    <div class="sizeBox"></div>
  </div>
</template>
<script>
import { toAppAdv } from "@/utils/index.js";
import ImgDecypt from "./ImgDecypt/index.vue";
export default {
  props: ["swiperData", "url", "token"],
  components: {
    ImgDecypt,
  },
  methods: {
    jumpAdv(item) {
      toAppAdv(item, this.url, this.token);
    },
  },
};
</script>
<style lang="scss" scoped>
.topic {
  height: 100%;
  background-color: rgb(247,247,248);
  padding: 17px 16px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .banner {
    height: 148.63px;
    border-radius: 5px;
    margin-bottom: 8px;
    overflow: hidden;
  }
  .sizeBox {
    height: 30px;
  }
  .no_data {
    height: 140px;
    width: 247px;
    margin: 150px auto;
    background: url("./../assets/png/no_data1.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .text {
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 14px;
      color: #969799;
    }
  }
}
</style>
