import Vue from 'vue'
import App from './App.vue'
import "@/assets/css/reset.css" //初始化css
import "@/assets/css/theme.scss" // 引入主题样式
import {
  Api
} from "@/utils/index.js";
// 1. 引入你需要的组件
import { List, PullRefresh, Cell, Toast, Loading, Overlay, Tab, Tabs, Swipe, SwipeItem, Image as VanImage, Lazyload } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
import "@/utils/flexible.js";
import filters from "@/utils/filters.js";

Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(Lazyload);

Vue.prototype.$Api = Api;
Vue.config.productionTip = false
// 注册全局过滤器
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));
new Vue({
  render: h => h(App),
}).$mount('#app')
