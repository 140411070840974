import axios from "axios";
const service = axios.create({
  timeout: 1000 * 30, // request timeout
});
// 请求拦截
service.interceptors.request.use(
  (config) => {
    return config;
  }
)
// 响应拦截
service.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data.code === 200) {
      return response;
    }
  }
)

export default service;