<template>
  <div class="entertainment">
    <div class="entertainment-header" v-if="appId != 30">娱乐</div>
    <div class="img-container m-scroll" v-if="swiperData.length >= 1">
      <div v-for="item in filterBaner('fixed')" :key="item.id">
        <div @click="jumpAdv(item)">
          <ImgDecypt :src="item.image" />
        </div>
      </div>
    </div>
    <div class="img-container m-scroll">
      <div v-for="item in filterBaner()" :key="item.id">
        <div @click="jumpAdv(item)">
          <ImgDecypt :src="item.image" />
        </div>
      </div>
    </div>
    <div class="row_box" v-if="appColList.length > 0">
      <div class="title">
        <div class="piece"></div>
        <span>推荐-热门</span>
      </div>
      <div class="en_list">
        <div
          class="row"
          v-for="item in appColList"
          :key="item.id"
          @click="jumpAdv(item)"
        >
          <div class="app_box">
            <div class="app">
              <ImgDecypt :src="item.icon" />
            </div>
            <div class="en_app_name">
              <div class="name">{{ item.name }}</div>
              <div class="count">
                下载次数：{{ item.downloadNum | downloadCount }}次
              </div>
            </div>
          </div>
          <div class="into">立即进入</div>
        </div>
      </div>
    </div>
    <div
      class="no_data"
      v-if="appRowList.length == 0 && appColList.length == 0"
    >
      <div class="text">暂无数据</div>
    </div>
  </div>
</template>
<script>
import { getHots } from "@/api/index.js";
import { toAdv } from "@/utils/index.js";
import ImgDecypt from "./ImgDecypt/index.vue";
export default {
  name: "Entertainment",
  props: ["appId", "swiperData"],
  components: {
    ImgDecypt,
  },
  data() {
    return {
      appRowList: [],
      appColList: [],
    };
  },
  created() {
    this.getHotList();
  },
  methods: {
    filterBaner(type = "scroll") {
      let bannerData = this.swiperData;
      if (type == "fixed") {
        bannerData = this.swiperData.filter((item, index) => index == 0);
      } else {
        bannerData = this.swiperData.filter((item, index) => index > 0);
      }
      return bannerData;
    },
    async getHotList() {
      let ret = await this.$Api(getHots, this.appId);
      if (ret.code == 200) {
        this.appRowList = ret.data.hengApp;
        this.appColList = ret.data.shuApp;
      }
    },
    jumpAdv(item) {
      toAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.entertainment-header {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: #000;
}
.m-scroll::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
}
.m-scroll::-webkit-scrollbar {
  width: 6px;
  height: 13px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.m-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 28px;
}
.m-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.entertainment {
  height: calc(100% - 90px);
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 5px 15px;
  background: #000;
  .img-container {
    max-height: 203px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: inherit;
    // padding: 14px;
    .img-box {
      margin-bottom: 10px;
      height: 94px;
      border-radius: 6px;
      overflow: hidden;
      img {
        width: 100% auto;
      }
    }
  }
  .row_box {
    .title {
      color: black;
      font-size: 14px;
      font-weight: 800;
      display: flex;
      margin: 10px 0;
      .piece {
        width: 8px;
        height: 21px;
        background-color: rgb(230, 37, 37);
        border-radius: 4px;
        margin-right: 3px;
      }
    }
    .two_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .row {
        height: 168px;
        width: 168px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        transform: translateZ(100px);
      }
    }
    .three_row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 5px;
      .row {
        height: 111px;
        width: 111px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        transform: translateZ(100px);
      }
    }
    .rows {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 5px;
      .row {
        margin-bottom: 10px;
        .app {
          height: 83px;
          width: 83px;
          overflow: hidden;
          border-radius: 10px;
          transform: translateZ(100px);
        }
        .en_app_name {
          color: black;
          font-size: 12px;
          font-weight: 800;
          text-align: center;
          margin: 3px 0;
        }
      }
    }
    .en_list {
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #313131;
        padding: 10px 0;
        .app_box {
          display: flex;
          .app {
            height: 56px;
            width: 56px;
            overflow: hidden;
            border-radius: 10px;
            transform: translateZ(100px);
          }
          .en_app_name {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-left: 5px;
            .name {
              font-size: 14px;
              font-weight: 800;
              color: rgb(0, 0, 0);
            }
            .count {
              font-size: 12px;
              color: rgb(147, 147, 147);
            }
          }
        }
        .into {
          height: 30px;
          width: 100px;
          border: 1px solid rgb(230, 37, 37);
          color: rgb(230, 37, 37);
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          font-weight: 500;
          border-radius: 15px;
        }
      }
      .row:last-of-type {
        border-bottom: unset;
      }
    }
    .number {
      padding: 3px 8px;
      color: black;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.5);
      width: auto;
      display: inline-block !important;
      display: inline;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 10px;
      font-weight: 500;
    }
  }
  .no_data {
    height: 160px;
    width: 160px;
    margin: 100px auto;
    background: url("./../assets/png/no_data.png") 100% 100% / cover no-repeat;
    position: relative;
    .text {
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 14px;
      color: #969799;
    }
  }
}
</style>
