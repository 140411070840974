<template>
  <div id="app">
    <Loufeng/>
  </div>
</template>

<script>
import Loufeng from './components/Loufeng.vue'

export default {
  name: 'App',
  components: {
    Loufeng
  }
}
</script>

<style>
#app {
  height: 100%;
  font-size: 16px;
  overflow: hidden;
}
</style>
