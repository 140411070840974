import axios from "@/utils/request";
import axios1 from "axios";
/**
 * 不经过action的异步
 * @param {Function} fn 异步函数
 * @param {} payload 参数
 * @param {boolean} asyncFlag 请求是否等待上一个请求结束
 */
let isPass = true;
let holdRequest = [];
export async function Api(fn, payload, asyncFlag = false) {
  if (asyncFlag) {
    let ret;
    if (fn) {
      holdRequest.push(fn);
    }
    if (!isPass) return;
    isPass = false;
    if (holdRequest.length > 0) {
      let popRequest = holdRequest.pop();
      try {
        let { data } = await popRequest(payload);
        ret = data;
      } catch (err) {
        ret = Promise.resolve(err);
      } finally {
        isPass = true;
        Api(undefined, undefined, true);
      }
      return ret;
    }
  } else {
    let { data } = await fn(payload);
    return data;
  }
}

/**
 *  截取url参数
 * @param {*} name 截取url参数名称
 * @returns
 */
export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substring(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

/**
 * 跳转广告
 */
export function toAdv(item) {
  let url = item.url;
  addAdvClick(item);
  window.open(url);
}

/**
 * app内跳转广告
 */
export function toAppAdv(item, url, token) {
  let link = item.url;
  addAppClick(item, url, token);
  window.open(link);
}

/**
 * 金主楼风app访问点击次数
 */
export async function addAdvClick(item) {
  let config = {
    headers: {
      "log-id": item.id,
      "log-type": "adv",
    },
  };
  axios.get("/api/application/dHH9h0Kp1074", config);
}

/**
 * app内的访问点击次数
 */
export async function addAppClick(item, url, token) {
  let data = {
    id: item.id,
    type: 1,
  };
  let config = {
    headers: {
      "log-type": "adv",
      Authorization: token,
    },
  };
  axios1.post(`${url}/api/app/ads/click`, data, config);
}
