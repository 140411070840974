<template>
  <div class="home">
    <div class="header">
      <div class="title"></div>
      <div class="domain">本站永久域名：<span>jhs99.cc</span></div>
    </div>
    <div class="main">
      <div class="gridBox">
        <div
          class="appBox"
          @click.stop="jumpAdv(item)"
          v-for="item in appRowList"
          :key="item.id"
        >
          <div class="appIcon">
            <ImgDecypt :src="item.icon" />
          </div>
          <div class="appName">{{ item.name }}</div>
        </div>
      </div>
      <div
        class="horizontalBox"
        @click.stop="jumpAdv(item)"
        v-for="item in appColList"
        :key="item.id"
      >
        <div class="hAppBox">
          <div class="hAppIcon">
            <ImgDecypt :src="item.icon" />
          </div>
          <div class="hAppInfo">
            <div class="hAppName">
              {{ item.name }}
            </div>
            <div class="hAppDesc">
              {{ item.desc }}
            </div>
          </div>
        </div>
        <div class="download">下载</div>
      </div>
      <div
        class="no_data"
        v-if="appRowList.length == 0 && appColList.length == 0"
      >
        <div class="text">暂无数据</div>
      </div>
      <div class="sizeBox"></div>
    </div>
  </div>
</template>
<script>
import { getHots } from "@/api/index.js";
import { toAppAdv } from "@/utils/index.js";
import ImgDecypt from "./ImgDecypt/index.vue";
export default {
  name: "Home",
  props: ["appId", "url", "token"],
  components: {
    ImgDecypt,
  },
  data() {
    return {
      appRowList: [],
      appColList: [],
    };
  },
  created() {
    this.getHotList();
  },
  methods: {
    async getHotList() {
      let ret = await this.$Api(getHots, this.appId);
      if (ret.code == 200) {
        this.appRowList = ret.data.hengApp;
        this.appColList = ret.data.shuApp;
      }
    },
    jumpAdv(item) {
      toAppAdv(item, this.url, this.token);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  background-color: rgb(247, 247, 248);
  color: rgb(38, 38, 38);
  padding: 14px 16px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      height: 36px;
      width: 64px;
      background: url("./../assets/png/title.png") no-repeat;
      background-size: 100% 100%;
    }
    .domain {
      span {
        color: rgb(255, 160, 1);
      }
    }
  }
  .main {
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    .gridBox {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 10px;
      grid-column-gap: 20px;
      margin-top: 25px;
      margin-bottom: 24px;
      .appBox {
        width: 68px;
        text-align: center;
        .appIcon {
          height: 68px;
          width: 68px;
          border-radius: 5px;
          overflow: hidden;
        }
        .appName {
          font-size: 12px;
          color: rgb(38, 38, 38);
          margin-top: 6px;
        }
      }
    }
    .horizontalBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .hAppBox {
        flex: 1;
        display: flex;
        align-items: center;
        .hAppIcon {
          height: 60px;
          width: 60px;
          border-radius: 5px;
          overflow: hidden;
        }
        .hAppInfo {
          flex: 1;
          margin: 0px 20px 0 7px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 60px;
          .hAppName {
            font-size: 13px;
            color: rgb(38, 38, 38);
          }
          .hAppDesc {
            font-size: 12px;
            color: rgb(128, 128, 128);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .download {
        height: 30px;
        width: 54px;
        border: 1px solid rgb(255, 160, 1);
        border-radius: 54px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        color: rgb(255, 160, 1);
      }
    }
    .no_data {
      height: 140px;
      width: 247px;
      margin: 150px auto;
      background: url("./../assets/png/no_data1.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .text {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 14px;
        color: #969799;
      }
    }
    .sizeBox {
      height: 30px;
    }
  }
}
</style>
