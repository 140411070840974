// 图片解密
export async function imgDecypt(url){
  if (process.browser) {
    let encoder = new TextEncoder();
    let KEY = encoder.encode("2019ysapp7527");
    try {
      return await new Promise(resolve => {
        let oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "arraybuffer";
        oReq.onload = function () {
          var arrayBuffer = oReq.response; // 注意:不是oReq.responseText
          if (arrayBuffer) {
            var bytes = new Uint8Array(arrayBuffer);
            for (let i = 0; i < 100; i++) {
              bytes[i] ^= KEY[i % KEY.length];
            }
            const blob = new Blob([bytes], {
              type: "image/png"
            });
            const url_1 = URL.createObjectURL(blob);
            resolve(url_1);
          }
        };
        oReq.send("");
      });
    } catch {
      console.log("解密失败");
    }
  }
}