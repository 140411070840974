<template>
  <div class="img-box">
    <transition name="fade">
      <img :src="decyImg" width="100%" height="100%" v-if="decyImg" />
    </transition>
    <div class="loading_box" v-if="!decyImg">
      <van-loading type="spinner" size="20" />
    </div>
    <!-- <van-image :src="decyImg" width="100%" height="100%" fit="cover" lazy-load v-if="decyImg">
      <template v-slot:loading>
        <van-loading type="spinner" size="20" />
      </template>
      <template v-slot:error>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image> -->
  </div>
</template>

<script>
import { imgDecypt } from '@/utils/cryption';
export default {
  name: 'ImgDecypt',
  props: {
    src: {
      type: String,
      required: true,
      default() {
        return "";
      },
    },
    isDecypt: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      decyImg: ""
    };
  },
  async created(){
    if (!this.isDecypt) {
      this.decyImg = this.src;
    } else {
      try {
        if (!this.src) return;
        imgDecypt(this.src).then((url) => {
          this.decyImg = url;
        });
      } catch (error) {
        console.log("失败了");
        console.log(error);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.img-box {
  height: 100%;
  width: 100%;
  .loading_box {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>